import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-31140fcb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-splash-screen" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: "app-splash-screen__background",
      src: _ctx.background,
      alt: "vaibe background"
    }, null, 8, _hoisted_2),
    _createElementVNode("img", {
      class: "app-splash-screen__logo",
      src: _ctx.logo,
      alt: "vaibe logo"
    }, null, 8, _hoisted_3)
  ]))
}